import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import workshopPageStyle from "../assets/jss/material-kit-react/views/workshopPage.js";
// core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Badge from "components/Badge/Badge.js";


const dashboardRoutes = [];
class WorkshopPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="MALWAREUNICORN"
          rightLinks={<HeaderLinks />}
          fixed
          {...rest}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container} >
            <div className={classes.title}>
              <h2 className={classes.title}>Workshops</h2>
              <h4>Welcome to reverse engineering workshops</h4>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card >
              <CardBody>
              <h4 className={classes.cardTitle}>PE Injection Study</h4>
              <p>This workshop will look at Cryptowall malware for the purposes of extracting information on the code injection technique in order to replicate for red team operation use.</p>

              <Badge color="warning">Windows</Badge>
              <Badge color="danger">APC Thread Injection</Badge>
              <Badge color="primary">Golang</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="workshops/peinjection.html">Start</Button>
                <small className={classes.cardDate}>Published June 26, 2021</small>
              </CardFooter>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card >
              <CardBody>
              <h4 className={classes.cardTitle}>MacOS Dylib Injection</h4>
              <p>This workshop will cover macOS Mach-O executable header parsing, compiling Go dylibs, entrypoint manipulation, shellcode, and dynamically loading dylibs into memory.</p>

              <Badge color="warning">MacOS</Badge>
              <Badge color="info">Dylib</Badge>
              <Badge color="danger">Shellcode</Badge>
              <Badge color="primary">Golang</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="workshops/macos_dylib_injection.html">Start</Button>
                <small className={classes.cardDate}>Published April 4, 2020</small>
              </CardFooter>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card>
              <CardBody>
              <h5 className={classes.cardTitle}>Reverse Engineering 101</h5>
              <p>11 sections. This workshop provides the fundamentals of reversing
              engineering Windows malware using a hands-on experience with
              RE tools and techniques.</p>
              <Badge color="primary">x86</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="workshops/re101.html">Start</Button>
                <small className={classes.cardDate}>Published May 14, 2019</small>
              </CardFooter>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card >
              <CardBody>
              <h4 className={classes.cardTitle}>Reverse Engineering 102</h4>
              <p>18 sections. This workshop build on RE101 and focuses on
              identifying simple encryption routines,
              evasion techniques, and packing.</p>
              <Badge color="primary">x86</Badge>
              <Badge color="warning">packing</Badge>
              <Badge color="info">encryption</Badge>
              <Badge color="danger">evasion</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="workshops/re102.html">Start</Button>
                <small className={classes.cardDate}>Published May 17, 2019</small>
              </CardFooter>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card >
              <CardBody>
              <h4 className={classes.cardTitle}>Flareon 6 2019 Writeups</h4>
              <p>This is a writeup of the 12 Challenges from the Flareon 6 CTF 2019.</p>
              <Badge color="primary">CTF</Badge>
              <Badge color="warning">Windows</Badge>
              <Badge color="info">Linux</Badge>
              <Badge color="danger">Android</Badge>
              <Badge color="danger">NES</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="workshops/flareon6_2019.html">Start</Button>
                <small className={classes.cardDate}>Published Sept 30, 2019</small>
              </CardFooter>
            </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={4}>
            <Card >
              <CardBody>
              <h4 className={classes.cardTitle}>Anti-Analysis Techniques</h4>
              <p>This is a survey of anti-analysis techniques. Will include some tips to bypass.</p>

              <Badge color="warning">Windows</Badge>
              <Badge color="info">anti-reversing</Badge>
              <Badge color="danger">anti-debugging</Badge>
              <Badge color="primary">anti-automation</Badge>
              </CardBody>
              <CardFooter>
                <Button color="primary" compontent="a" href="#">Disabled</Button>
                <small className={classes.cardDate}>Coming Soon!</small>
              </CardFooter>
            </Card>
            </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );}
  }

export default withStyles(workshopPageStyle)(WorkshopPage);
